<template>

  <b-modal
    id="modal-help"
    centered
    title="O que tem na tela &quot;Pacientes&quot;?"
    ok-only
    ok-title="VOLTAR"
    @ok="close"
    @hidden="close"
  >
    <template>
      <b-img
        src="@/assets/images/icons/question.png"
        class="m-1"
        center
        width="48.8"
      />
      <br>
      <p class="text-justify">
        Aqui você consulta materiais disponibilizados pela Cuco e também pode fazer upload de arquivos em formato PDF para enviar conteúdos aos seus pacientes.
      </p>
    </template>
  </b-modal>

</template>

<script>
import {
  VBModal, BModal, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  methods: {
    close() {
      this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
    },
  },
}
</script>

<style scoped>
  .modal .modal-header {
    background-color: #0058F9;
  }
  h5 {
     color: white;
  }
</style>
