<template>
  <div>
    <b-card>
      <p>Nesta página você encontra diversos conteúdos informativos disponibilizados pela Cuco.
        Além disso, você também pode adicionar arquivos em formato PDF, JPEG e PNG para compartilhar com
        seus pacientes. Para compartilhar no aplicativo Cuco do seu paciente, vá no menu
        “<img
          width="13"
          src="@/assets/images/icons/icone-pacientes.jpg"
        > Pacientes”.</p>

      <p class="mb-0">
        Você, na qualidade de profissional da área médica e usuário da Plataforma DR. CUCO, tem plena ciência que é o único responsável pelo conteúdo, arquivo ou qualquer outra informação que será veiculada ou enviada ao seus Pacientes. O DR. CUCO não se responsabiliza por qualquer ato praticado por você mediante o uso incorreto da nossa plataforma, devendo sempre observar todas as regras éticas e técnicas da profissão, bem como a legislação vigente.
      </p>
    </b-card>

    <b-card
      no-body
      class="mb-2 pb-2"
    >
      <b-col cols="12">
        <library-user />
      </b-col>
    </b-card>
    <b-card
      no-body
      class="mt-3 pb-2"
    >
      <b-col cols="12">
        <library-cuco />
      </b-col>
    </b-card>
    <library-help />
  </div>
</template>

<script>
import {
  VBModal, BCard, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LibraryCuco from './Library-cuco.vue'
import LibraryUser from './Library-user.vue'
import LibraryHelp from './Library-help.vue'

export default {
  components: {
    BCard,
    BCol,

    LibraryCuco,
    LibraryUser,
    LibraryHelp,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  computed: {
    showHelp() {
      return this.$store.state.breadcrumb.showHelp
    },
    showCreate() {
      return this.$store.state.favorite.showCreate
    },
  },

  watch: {
    showHelp(value) {
      if (value === true) {
        this.$bvModal.show('modal-help')
      }
    },
    showCreate(value) {
      if (value === true) {
        this.$bvModal.show('modal-create')
      }
    },
  },

  beforeMount() {
    this.$store.commit('library/UPDATE_LINK', 'library')
  },

  mounted() {
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
  },

  methods: {
    create() {
      this.$store.commit('favorite/UPDATE_SHOW_CREATE', true)
    },
  },

}
</script>
